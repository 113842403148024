/* ---------- MAIN CONTAINER ---------- */
.main-container {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  width: 100%;
  overflow: hidden; 
}
/* ---------- TABLE CONTAINER ---------- */
.table-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-table-border);
  overflow: hidden; 
}


/* ---------- TABLE TITLE ---------- */
.table-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
  background: var(--color-table-title-background);
  border-bottom: 1px solid var(--color-table-border);
  position: sticky;
  top: 0;
  z-index: 30;
}

/* ---------- TABLE STYLING ---------- */
.table {
  width: 100%;
  border-collapse: collapse;
  /* border-spacing: 0; */
  background: var(--color-glass-effect);
  border: 1px solid var(--color-table-border);
}

/* ----------  Scrollable Table Wrapper ---------- */
.table-wrapper {
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
  scroll-behavior: smooth; 
}

/* ------- Table head ------------*/
.table-header {
  background: var(--color-table-header-background);
  /* color: var(--color-primary-text); */
  text-transform: uppercase;
  border-bottom: 1px solid var(--color-table-border);
  position: sticky;
  top: 0;
  z-index: 20;
}

.table-body td, .table-header th {
  padding: 8px;
}

/* === Table Rows (Floating Card Effect) === */
.table-body tr {
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
}

.table-body tr:nth-child(even) {
  background: var(--color-table-row-even);
}

.table-body tr:nth-child(odd) {
  background: var(--color-table-row-odd);
}

/* === Hover Effect (Apple-Like Floating Row) === */
.table-body tr:hover {
  background: rgba(230, 240, 255, 0.7);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.table-footer {
  background: var(--color-table-footer-background);
  text-align: center;
  border-top: 1px solid var(--color-table-border);
  position: sticky;
  bottom: 0;
  z-index: 20;
}

.sort-icon {
  margin-left: 5px;
  opacity: 0.7;
  transition: opacity 0.2s;
}


/* === Horizontal Scroll Inside the Container === */
.table-wrapper::-webkit-scrollbar {
  width: 2px; 
  height: 2px;
}

/* === Scrollbar Track (Background) === */
.table-wrapper::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2); /* Light track */
  border-radius: 5px;
}

/* === Scrollbar Thumb (Draggable Part) === */
.table-wrapper::-webkit-scrollbar-thumb {
  background: rgba(20, 40, 70, 0.5); /* Subtle thumb color */
  border-radius: 5px;
}

/* === Hover Effect: Slightly Thicker Scrollbar === */
.table-wrapper:hover::-webkit-scrollbar-thumb {
  background: rgba(20, 40, 70, 0.7);
  width: 4px; /* Make scrollbar wider on hover */
  height: 4px;
}

/* === Dark Mode Scrollbar === */
body.dark-mode .table-wrapper::-webkit-scrollbar-track {
  background: rgba(100, 120, 150, 0.2);
}

body.dark-mode .table-wrapper::-webkit-scrollbar-thumb {
  background: rgba(100, 120, 150, 0.6);
}
/* === Responsive Title & Header Text === */
@media (max-width: 768px) {
  /* Shrink Table Title */
  .table-title {
    font-size: 16px; /* Reduce size */
    padding: 10px; /* Reduce padding */
  }

  /* Shrink Table Header */
  .table th {
    font-size: 12px; /* Smaller text for mobile */
    padding: 8px; /* Less padding */
  }

  /* Reduce table cell padding */
  .table td {
    font-size: 12px;
    padding: 8px;
  }
}

/* Extra Small Screens (Phones) */
@media (max-width: 480px) {
  .table-title {
    font-size: 14px;
    padding: 8px;
  }

  .table th,
  .table td {
    font-size: 10px;
    padding: 6px;
  }
}

/* ---------- THREAT SEVERITY LABELS ---------- */
.threat-severity {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.threat-severity-label {
  width: 85px;
  display: inline-block;
  padding: 6px 14px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.critical {
  background-color: var(--color-severity-critical);
  color: white;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}

.high {
  background-color: var(--color-severity-high);
  color: white;
  box-shadow: 0 0 8px rgba(255, 165, 0, 0.5);
}

.moderate {
  background-color: var(--color-severity-moderate);
  color: black;
  box-shadow: 0 0 8px rgba(255, 255, 0, 0.5);
}

.low {
  background-color: var(--color-severity-low);
  color: white;
  box-shadow: 0 0 6px rgba(0, 128, 0, 0.5);
}

.very-low {
  background-color: var(--color-severity-very-low);
  color: black;
  box-shadow: 0 0 5px rgba(144, 238, 144, 0.5);
}

