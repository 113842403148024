.search-container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* padding: 6px 12px; */
  /* background: #2C3E50; */
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

/* Initially, only show the search icon */
.search-container .search-icon {
  /* border: 1px solid #ce9f2c; */
  /* color: white; */
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
}

.search-icon:hover {
  /* color: white;
  border: 1px solid white;
  transform: scale(1.05); */
  border: 1px solid #ce9f2c;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}


/* Hide input initially */
.search-container input {
  width: 0;
  opacity: 0;
  padding: 10px; 
  /* padding-left: 35px; */
  border: none;
  outline: none;
  background: transparent;
  /* color: white; */
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

/* When search input is expanded */
.search-container.expanded {
  border: 1px solid #f1c40f;
  box-shadow: 0 0 10px rgba(241, 196, 15, 0.6);
}

.search-container.expanded input {
  width: 220px;
  opacity: 1;
}

/* Placeholder Styling */
.search-container input::placeholder {
  color: #bbb;
}

/* Progress bar effect */
.search-container .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: #f1c40f;
  width: 0%;
  transition: width 0.3s ease-in-out;
}

/* When typing, expand progress bar */
.search-container.expanded .progress-bar {
  width: 100%;
}

/* Clear Button */
.search-container .clear-icon {
  font-size: 16px;
  color: #bbb;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.2s ease-in-out;
}

.clear-icon:hover {
  color: #ff4d4d;
}
