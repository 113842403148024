/* FORM CONTAINER */
.form-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
  overflow: auto;
  padding: 1rem;
}

/* FORM GROUP */
.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

/* FORM LABEL */
.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: var(--color-text);
  transition: all 0.3s ease-in-out;
}

.dark .form-label {
  color: var(--color-text-dark);
}

/* INPUT FIELDS */
.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-text);
  /* background-color: #ffffff; */
  border: 2px solid var(--color-border-dark);
  border-radius: 0.625rem;
  transition: all 0.3s ease-in-out;
}

/* DARK MODE */
.dark .form-control {
  color: var(--color-text-dark);
  background-color: var(--color-card-dark);
  border-color: var(--color-border-dark);
}

/* HOVER & FOCUS STATES */
.form-control:hover {
  border-color: var(--color-btn-hover);
}

.form-control:focus {
  outline: none;
  border-color: var(--color-btn-primary);
  box-shadow: 0 0 10px rgba(206, 159, 44, 0.4);
}

/* TEXTAREA */
.form-control[type="textarea"] {
  height: 120px;
  max-height: 250px;
  resize: vertical;
}

/* SELECT & MULTI-SELECT */
.form-control[type="select"],
.form-control[type="multi-select"] {
  cursor: pointer;
}

/* CHECKBOXES & RADIO BUTTONS */
.form-check-input {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--color-border-dark);
  border-radius: 0.375rem;
  transition: all 0.3s ease-in-out;
}

.dark .form-check-input {
  border-color: var(--color-border-dark);
}

/* CHECKBOX HOVER */
.form-check-input:hover {
  border-color: var(--color-btn-hover);
}

/* CHECKED STATE */
.form-check-input:checked {
  background-color: var(--color-btn-primary);
  border-color: var(--color-btn-primary);
}

/* ERROR MESSAGE */
.invalid-feedback {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #dc3545;
}

/* SUCCESS MESSAGE */
.valid-feedback {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #28a745;
}

/* DISABLED STATE */
.form-control:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

.dark .form-control:disabled {
  background-color: #4b5563;
  color: #9ca3af;
}
