
.dropdown-container {
    position: relative;
    width: 100%;
    /* z-index: 20; */
  }
  
  .dropdown-header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    cursor: default;
    color: #212529;
    background-color: #fff;
    border: 1px solid #878e8e;
    border-radius: 0.625rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    
  }
  .dark .dropdown-header {
    color: rgb(241 245 249);
    background-color:#1F2937
  }
  .dropdown-header .dropdown-header-value {
    max-width: 500px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }
  .dropdown-header-selected-clear-btn {
    cursor: pointer;
    background: none;
    border: 0;
    padding: 0;
    display: flex;
  }
  
  .dropdown-search-clear-btn {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: none;
    border: 0;
    padding: 0 5px;
  }
  .dropdown-header-down-icon {
    display: block;
    vertical-align: middle;
  }
  
  .dropdown-menu {
  position: absolute;
  z-index: 100;
  width: 100%;
  padding-top: .317rem; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 0.625rem;
  }
  .dropdown-menu .select-content {
    overflow: hidden;
    border-radius: 0.625rem;
    background: #fff;
  }
  .select-content {
    border: 1px solid #878e8e;
    border-radius: 0.625rem;
    
  }
  .dark .select-content {
    color: rgb(241 245 249);
    background-color:#1F2937
  }
  
  .dropdown-search {
    width: 100%;
    position: relative;
    border-bottom: 1px solid;
  }
  
  .dropdown-search input {
    background: none;
    height: 38px;
    padding: 0 0.725rem;
    width: 100%;
    outline: 0;
    border: 0;
    font-size: 1em;
  }
  
  .dropdown-search input:focus{
    background: #fff;
  }
  
  .multi-select-item {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding:0.5rem;
    outline-offset: -1px;
  }
  
  .options-list-item {
    display: flex;
    align-items: center;
  }
  .options-list-item input {
    margin: 0 5px 0 0;
    border-radius: 30%;
    background-color:#ebebec
  }
  
  .dark .options-list-item input {
    background-color:#1F2937
  
  }
  .multi-select-item .selected {
    background: white;
  }
  
  .CronusSelect {
    position: relative;
    width: 100%;
  }
  
  .options {
    max-height: 260px;
    overflow-y: auto;
    margin: 0;
    padding-left: 0;
  }
  
  .options li {
    list-style: none;
    padding: 0.5rem; 
    cursor: pointer;
    border-top: 1px solid #dcdcdc;
  }
  
  .options li:first-child {
    border-top: none;
  }
  
  .options li:hover {
    background-color: #f1f1f1;
  }
  
  .dark .options li:hover {
    background-color: #878e8e;
  }
