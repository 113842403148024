.paginationBttns {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
}

.paginationBttns ul {
  list-style: none;
}
.paginationBttns li {
  padding-top: 18px;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid #ce9f2c;
  color: #273c63;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
.dark .paginationBttns a {
  color: rgb(156 163 175);
  border: 1px solid rgb(75 85 99);
}
.paginationBttns a:hover {
  color: white;
  background: #ce9f2c;
}

.paginationActive a {
  background: #273c63;
  color: white;
  opacity: 0.9;
  cursor: default;
}
.paginationDisabled a {
  color: rgb(156 163 175);;
  background: gray;
  opacity: 0.9;
  cursor: default;
}

