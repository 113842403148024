/* @import 'assets/css/utilities/styles.css';  */
/* @import 'assets/css/utilities/style.css'; */
/* @import 'assets/css/utilities/stylesFromClients.css'; */
/* @import 'assets/css/utilities/Test.css'; */



/* ---------- TAILWIND IMPORTS ---------- */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


/*  Global Variables (Colors & Themes) */

@import "../src/assets/css/utilities/colors.css";

/* @import 'assets/css/utilities/darkMode.css'; */
/* @import 'assets/css/utilities/animations.css'; */
@import 'assets/css/utilities/typography.css';


/* Import Component-Specific Styles */
@import "../src/assets/css/components/pagination.css";
@import "../src/assets/css/components/loading.css";
@import "../src/assets/css/components/table.css";
@import "../src/assets/css/components/btn.css";
@import "../src/assets/css/components/searchInput.css";
@import "../src/assets/css/components/navbar.css";
@import "../src/assets/css/components/form.css";
@import "../src/assets/css/components/select.css";
@import "../src/assets/css/components/cyberMindDropDown.css";
@import "../src/assets/css/components/chart.css";
@import "../src/assets/css/components/modal.css";
@import "../src/assets/css/components/fileUpload.css";

/* ---------- BASE HTML STYLES (ONLY GLOBAL SETTINGS HERE) ---------- */
html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
}

body {
  font-family: 'SF Pro Display', 'Inter', sans-serif;
  font-size: 16px;
  background: var(--color-background);
  color: var(--color-text-primary);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.critical_color {
  background-color: #7a1a1c;
}
.high_color {
  background-color: orange;
}
.medium_color {
  background-color: yellow;
}
.low_color {
  background-color: green;
}
.product_color {
  background-color: #767678;
}

/*  Google Sankey Chart */

.google-Sankey-node:hover rect {
  stroke-width: 3;
  stroke: #000;
}
.glass {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 8px;
}
