/* === Manage Button (Filter Icon) === */
.btn-manage {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 6px;
}

.btn-manage:hover {
  border: 1px solid #273c63;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

/* === Dropdown Container === */
.manage-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 250px;
  z-index: 100;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeIn 0.3s forwards ease-in-out;
}

body.dark-mode .manage-dropdown {
  background: #1e1e1e;
  color: white;
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.1);

}
/* === Dropdown Item === */
.dropdown-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

body.dark-mode .dropdown-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

}

.toggle-btn {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
}

.toggle-btn:hover {
  color: #ce9f2c;
}

.options-group {
  margin-top: 5px;
  display: flex;
  gap: 10px;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  border-radius: 5px;
}

/* === When Section is Expanded === */
#rows-options,
#severity-options {
  max-height: 100px;
  opacity: 1;
  /* background: rgba(240, 240, 240, 0.5); */
  padding: 10px;
}

body.dark-mode #rows-options,
body.dark-mode #severity-options {
  background: rgba(255, 255, 255, 0.1);
}

/* === Option Buttons (With Subtle Shadow on Hover) === */
.option-btn {
  font-size: 14px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.15);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.option-btn:hover {
  background: #f0f0f0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.option-btn.selected {
  background: #f39c12;
  box-shadow: 0 0 10px rgba(255, 183, 77, 0.7);
}

/* === Fade-in Animation === */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
