/* MODAL BACKDROP */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  transition: opacity 0.3s ease-in-out;
}
.modal-backdrop.show {
  opacity: 1;
}
.modal-backdrop.fade {
  opacity: 0;
}

/* MODAL CONTAINER */
.modal {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
  display: none;
  width: 90%;
  max-height: 90vh; 
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}
.modal.show {
  display: block;
}

/* MODAL DIALOG */
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh; 
  padding: 1rem;
}

.modal.fade .modal-dialog {
  transform: scale(0.95);
  opacity: 0;
}
.modal.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

/* MODAL CONTENT */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; 
  max-width: inherit; 
  pointer-events: auto;
  border-radius: 1rem;
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  max-height: 85vh; 
  overflow-y: auto; 
}

.dark .modal-content {
  background-color: rgb(31 41 55);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-sm {
  max-width: 400px;
}
.modal-md {
  max-width: 600px;
}
.modal-lg {
  max-width: 900px;
}
.modal-xl {
  max-width: 1140px;
}

/* MODAL HEADER */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  font-size: 1.25rem;
  font-weight: bold;
}
.dark .modal-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* MODAL BODY */
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
}


/* MODAL FOOTER */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #ccc;
}
.dark .modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* BUTTON STYLING */
.modal-footer button {
  margin-left: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.btn-primary {
  background-color: var(--color-btn-primary);
  color: white;
}
.btn-primary:hover {
  background-color: var(--color-btn-hover);
}
