body {
    font-family: 'SF Pro Display', 'Inter', sans-serif;
    font-size: 16px;
    color: var(--color-text-primary);
  }
  
  .table-title {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
  }
  
  .table-header, .pagination-btn {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  
  .table-body td {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  
  .table-footer {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
  }
  