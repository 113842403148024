/* GENERAL BUTTON STYLES */
button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 0.5rem 1rem;
}

/* ---------- BUTTON ADD UPLOAD DOWNLOAD ---------- */
.btn_file_upload,
.btn_file_download,
.btn-add-new,
.btn-cancel,
.btn-append, 
.btn-replace {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px;
  /* transition: all 0.3s; */
}

.btn_file_upload:hover,
.btn_file_download:hover,
.btn-add-new:hover,
.btn-cancel:hover,
.radio-label:hover {
  /* color: white;
  border: 1px solid white;*/
  /* transform: scale(1.05);  */
  border: 1px solid #273c63;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

/* Space between buttons */
.btn_file_upload, .btn_file_download, .btn-add-new {
  margin-left: 6px;
}

/* Button Icons */
.icon {
  font-size: 18px;
}


.btn-append {
  background: #cea02cd3;
}

.btn-append:hover {
  background: #c7982c;
}

.btn-replace {
  background: #8f2e2ef0;
}

.btn-replace:hover {
  background: #7a2626;
}


/* EDIT & DELETE BUTTONS */
.btn-edit, .btn-delete {
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
}

.btn-delete {
  background-color: #8a3131;
  color: white;
}

.btn-delete:hover {
  background-color: #6e2323;
}

/* TOOLTIP ANIMATION */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  background: var(--color-card-bg-dark);
  color: white;
  text-align: center;
  padding: 1rem;
  border-radius: 5px;
  font-size: 12px;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%) scale(0.9);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
  white-space: nowrap;
  z-index: 100;
}

/* Tooltip Appears on Hover */
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

/* Dark Mode Tooltip */
.dark .tooltip .tooltip-text {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
}

.btn-info {
  display: inline-block;
}

.radio-group {
  display: flex;
  gap: 12px;
  align-items: center;
}

.radio-label {
  font-size: 14px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.15);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.radio-label.active {
  background: #f39c12;
  box-shadow: 0 0 10px rgba(255, 183, 77, 0.7);
}

.radio-label input {
  display: none; 
}

.btn-filter {
  background: #f39c12;
  padding: 8px;
  border-radius: 6px;
}
