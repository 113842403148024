:root {
  /* Light Mode Colors */
  --color-text-primary: rgba(12 10 9 .5);;
  --color-primary: #273c63;
  --color-secondary: #ce9f2c;

  /* --color-background:  linear-gradient(to top right, rgba(39, 60, 99, 0.4), rgba(255, 255, 255, 0.7)); */
  --color-background:  linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(240, 245, 255, 0.7));
  --color-table-title-background:  linear-gradient(to top left, rgba(39, 60, 99, 0.7), rgba(255, 255, 255, 0.2));
  --color-table-header-background: linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(240, 245, 255, 0.7));
  --color-table-footer-background: linear-gradient(90deg, rgba(240, 248, 255, 0.8), rgba(255, 255, 255, 0.6));
  --color-border: rgba(217, 225, 234, 0.3);
  --color-text: rgb(156, 162, 171);
  --color-text-h2: rgba(15, 23, 41, 1);
  --color-text-p: rgba(51, 65, 85, 1);

  --color-text: #1e293b; 
  --color-text-muted: #64748b; /* Muted text color */
  
  --color-header-bg: #ffffff; /* White header background */
  --color-card-bg: #ffffff; /* White card background */
  --color-card-border: #d1d5db; /* Soft card border */
  --color-card-bg-dark: rgba(39, 60, 99, 0.7); 

  
  --color-btn-primary: #273c63; 
  --color-btn-primary-hover: #ce9f2c; 
  --color-btn-secondary: #94a3b8;
  --color-btn-secondary-hover: #64748b; /* Darker gray-blue hover */

  
  /* Table Colors */
  --color-table-row-odd: rgba(240, 245, 255, 0.6); 
  --color-table-row-even: rgba(255, 255, 255, 0.5); 
  --color-table-border: rgba(217, 225, 234, 0.5);

  /* Button & Interactivrgb(27, 29, 32)nts */
  --color-btn-hover: #273c63;
  --color-btn-primary: #ce9f2c;


  
  /* Gradients & Effects */
  --color-glass-effect: rgba(255, 255, 255, 0.4);
  --gradient-primary: linear-gradient(90deg, #0a192f, #112a4d);
  --glassy-background: rgba(255, 255, 255, 0.1);
  --glassy-border: rgba(255, 255, 255, 0.2);

  /* Chart Colors */
  --color-chart-one: #375e87;
  --color-chart-two: #ce9f2c;
  --color-chart-three: #872646;
  --color-chart-four: #e87c10;
  --color-chart-five: #70798A;
  --color-chart-six: #00BFFF;

  /* Vulnerability Colors */
  --color-vulnerable-critical: #862E40;
  --color-vulnerable-high: #CC5555;
  --color-vulnerable-medium: #E29252;
  --color-vulnerable-low: #F1CA65;
  --color-vulnerable-info: #66ACE0;

  /* Threat Severity */
  --color-severity-very-low: #90EE90;
  --color-severity-low: #008000;
  --color-severity-moderate: #FFFF00;
  --color-severity-high: #FFA500;
  --color-severity-critical: #800000;
}

/* ---------- Dark Mode Overrides ---------- */
html.dark {
  --color-background: linear-gradient(135deg, #0a192f, #112a4d, #1e293b); 
  --color-table-title-background: linear-gradient(90deg, #0a192f, #112a4d, #1e293b); 
  --color-table-footer-background: linear-gradient(90deg, #0a192f, #112a4d, #1e293b); 
  --color-table-header-background: linear-gradient(90deg, #0a192f, #112a4d, #1e293b); 
  --color-table-row-even: rgba(255, 255, 255, 0.1);;
  --color-table-row-odd: rgba(255, 255, 255, 0.05);
  --color-table-border: #4b5563;

  --color-border: rgba(255, 255, 255, 0.1);
  --color-text-primary:  #cbd5e1;
  --color-text-h2: rgba(226, 232, 240, 1);
  --color-text-p: rgba(148, 163, 184, 1);
  --color-glass-effect: rgba(17, 42, 77, 0.5);
}
